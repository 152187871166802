@import './fonts';
$button-margin: 15px 30px;
$border-radius: 8px;
%position {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}
%fast-transition {
    transition: all .150s ease;
}

* {
	padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.page {
	width: 100%;
	min-height: 100vh;
	background: #2e353f;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
    position: relative;
}
.top-bg {
	position: absolute;
	opacity: .7;
	overflow: hidden;
	width: 100%;
	display: flex;
	justify-content: center;
}
.logo {
	position: absolute;
	top: 20px;
	margin-left: auto;
	z-index: 10;
}
.bottom-bg {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 345px;
	background: linear-gradient(to bottom, rgba(201, 214, 233, 0), rgba(201, 214 ,233, .08));
}
.container {
	margin: 160px auto 0;
    width: 1200px;
    display: flex;

    @media (max-width: 1199.98px) {
        width: 100%;
    }
    @media (max-width: 991.98px) {
        width: 100%;
    }
    @media (max-width: 767.98px) {
        width: 100%;
    }
    @media (max-width: 575.98px) {
        width: 100%;
    }
}
.content {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}
.link-button {
    position: relative;
    width: calc(100% / 2 - 120px);
    margin: $button-margin;
    border-radius: $border-radius;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: 1199.98px) {
        width: calc(100% / 2 - 30px);
        margin: 15px;
    }
    @media (max-width: 991.98px) {
        width: calc(100% / 2 - 40px);
        margin: 15px 10px;
    }
    @media (max-width: 767.98px) {
        width: 480px;
    }
    @media (max-width: 575.98px) {
        width: 290px;
        margin: 10px 0;
    }    

    
    &:hover .overlay {
        opacity: 0;
    }
    &:hover &__over-plate {
        background: linear-gradient(to bottom, rgba(94, 208, 240, .6), rgba(140, 19, 144, .6));
    }
    &:hover &__media {
        visibility: visible;
    }
    
    &__button {
        position: relative;
        width: 100%;
        min-height: 220px;
        border-radius: $border-radius;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #121212;

        @media (max-width: 991.98px) {
            min-height: 155px;
        }
        @media (max-width: 767.98px) {
            min-height: 220px;
        }
        @media (max-width: 575.98px) {
            min-height: 130px;
        }
    }

    &__over-plate {
        position: absolute;
        left: -10px;
        top: -10px;
        bottom: 10px;
        right: 10px;
        background: linear-gradient(to bottom, rgba(94, 208, 240, .2), rgba(140, 19, 144, .2));
        border-radius: $border-radius;
        z-index: 30;
        @extend %fast-transition;
    }

    &__media {
        @extend %position;
        overflow: hidden;
        border-radius: $border-radius;
        visibility: hidden;

        .media-item {
            width: 100%;
        }
    }

    &__text {
        position: absolute;
        z-index: 100;
        color: #fff;
        font-family: 'Segoe UI';
        font-weight: 300;
        font-size: 3.5rem;

        @media (max-width: 991.98px) {
            font-size: 2.5rem;
        }
        @media (max-width: 575.98px) {
            font-size: 2rem;
        }

        &_smaller {
            font-size: 2.5rem;

            @media (max-width: 991.98px) {
                font-size: 1.8rem;
            }
            @media (max-width: 575.98px) {
                font-size: 1.5rem;
            }
        }
    }

    &__expansion {
        transition: all .250s ease;
    }

    .expansion {
        width: 100%;
        max-height: 0;
        padding: 0;
        border-radius: $border-radius;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        opacity: 0;

        &__link {
            position: relative;
            width: 100%;
            min-height: 55px;
            border-radius: $border-radius;
            background-color: rgba(#000, .5);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }

            &:hover {
                background-color: rgba(#121212, .5);
            }
        }

        &__text {
            position: absolute;
            z-index: 100;
            color: #fff;
            font-family: 'Segoe UI';
            font-size: 1rem;
            text-align: center;
        }

        &_open {
            max-height: 220px;
            opacity: 1;
            padding: 10px 0;
            transition: all .250s ease;
        }
    }
}
.overlay {
    @extend %position;
    @extend %fast-transition;
    background: rgba(0, 0, 0, .5);
    border-radius: $border-radius;
    z-index: 10;
}
.w-100 {
    width: 100%;
}